<template>
    <div class="message-panel">
        <div class="white-bg">
        <h3 class="g-title">回复消息</h3>
        <div class="msg-reply-panel" style="padding-top:50px;padding-right:50px;">
            <el-form>
                <!-- <el-form-item label="标题">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>-->
                <el-form-item label="内容">
                    <el-input type="textarea" placeholder="请输入您的回复" v-model="form.content"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="returnPage">返回</el-button>
                    <el-button @click="doReply">发送</el-button>
                </el-form-item>
            </el-form>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "reply",
    components: {},
    data() {
        return {
            form: {
                title: "",
                content: ""
            }
        };
    },
    methods: {
        returnPage() {
            this.$router.push(
                "/user/message/detail?id=" + this.$route.query.id
            );
        },
        doReply() {
            this.post(
                "/message/message/reply_pc",
                {
                    id: this.$route.query.id,
                    // title: this.form.title,
                    content: this.form.content
                },
                data => {
                    if (data.code == 200) {
                        this.$message.success(data.msg);
                        this.$router.push("/user/message");
                    } else {
                        this.$message.error(data.msg);
                    }
                }
            );
        }
    }
};
</script>
<style lang="less">
@import "../../../styles/message.less";
</style>


